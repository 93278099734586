<template>
  <DialogFullScreen
    :title="'Id' in opportunity ? 'Editar oportunidad' : 'Nueva oportunidad'"
    :visible="visible"
    @closeDialog="sendCancel"
  >
    <v-stepper class="mt-4 mx-0 pa-0" v-model="currentStep">
      <v-stepper-header class="my-stepper">
        <v-stepper-step editable :complete="currentStep > 1" step="1">
          General
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step editable :complete="currentStep > 2" step="2">
          Solución
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step editable step="3"> Cross selling </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="opportunity.CompanyClientId"
                :items="clients"
                prepend-icon="mdi-domain"
                item-text="CompanyClients.Name"
                item-value="CompanyClients.Id"
                label="Cliente"
                ref="client"
                :rules="[
                  () =>
                    !!opportunity.CompanyClientId || 'Este campo es requerido'
                ]"
                @change="getClientData(opportunity.CompanyClientId)"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12">
              <v-switch
                v-model="opportunity.Mustwin"
                label="¿Esta oportunidad es un Must Win?"
              ></v-switch>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="opportunity.Name"
                prepend-icon="mdi-file-document-outline"
                label="Nombre"
                ref="nombre"
                :rules="[() => !!opportunity.Name || 'Este campo es requerido']"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-autocomplete
                v-model="opportunity.SectorId"
                :items="sectors"
                prepend-icon="mdi-factory"
                item-text="Name"
                item-value="Id"
                label="Sector"
                ref="sector"
                :rules="[
                  () => !!opportunity.SectorId || 'Este campo es requerido'
                ]"
                :disabled="contacts.length < 1"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12">
              <v-menu
                transition="scale-transition"
                nudge-bottom="10"
                offset-y
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="opportunity.Deadline"
                    label="Fecha de cierre"
                    prepend-icon="mdi-calendar"
                    readonly
                    ref="deadline"
                    :rules="[
                      () => !!opportunity.Deadline || 'Este campo es requerido'
                    ]"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  bottom
                  v-model="opportunity.Deadline"
                  full-width
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12">
              <v-switch
                v-model="opportunity.HasTakedecision"
                label=" ¿Tienes acceso a quien toma la decisión de la compra?"
              ></v-switch>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="opportunity.TakeDecision"
                prepend-icon="mdi-account-tie"
                label="¿Quien?"
                :disabled="!opportunity.HasTakedecision"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-autocomplete
                v-model="opHasContacts"
                :items="contacts"
                :item-text="getContactName"
                item-value="Id"
                chips
                small-chips
                label="Otros contactos"
                prepend-icon="mdi-account-multiple"
                multiple
                :disabled="contacts.length < 1"
              >
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6"
              ><v-btn text color="primary" @click="currentStep = 2">
                Siguiente
              </v-btn></v-col
            >
            <v-col cols="6">
              <v-btn text @click="sendCancel"> Cancelar </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="opportunity.Budget"
                prepend-icon="mdi-currency-usd"
                suffix="MXN"
                type="number"
                min="0"
                label="Presupuesto"
                ref="presupuesto"
                :rules="[
                  () => !!opportunity.Budget || 'Este campo es requerido'
                ]"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-autocomplete
                v-model="tmpBU"
                :items="bUnits"
                prepend-icon="mdi-domain"
                item-text="Name"
                item-value="Id"
                label="Unidad de negocio"
                ref="bunits"
                :disabled="blockBu"
                :rules="[() => !!tmpBU || 'Este campo es requerido']"
                @change="getBUData(tmpBU)"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12">
              <v-autocomplete
                v-model="opHasSolutions"
                :items="solutions"
                item-text="Name"
                item-value="Id"
                chips
                small-chips
                prepend-icon="mdi-brain"
                label="Soluciones ofrecidas por la unidad de negocio"
                multiple
                ref="solutions"
                :rules="[() => !!opHasSolutions || 'Este campo es requerido']"
                :disabled="solutions.length < 1"
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12">
              <v-autocomplete
                v-model="opHasSolutionsType"
                :items="solutionTypes"
                item-text="Name"
                item-value="Id"
                chips
                small-chips
                prepend-icon="mdi-brain"
                label="Tipo de soluciones ofrecidas"
                ref="solutionsType"
                :rules="[
                  () => !!opHasSolutionsType || 'Este campo es requerido'
                ]"
                multiple
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12">
              <v-textarea
                v-model="opportunity.Detail"
                prepend-icon="mdi-comment"
                label="Detalle de la oportunidad"
                rows="1"
                auto-grow
                ref="notes"
              ></v-textarea>
            </v-col>

            <v-col cols="12">
              <v-switch
                v-model="opportunity.HasRival"
                label="¿Tenemos competencia?"
              ></v-switch>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="opportunity.Rival"
                prepend-icon="mdi-account-alert"
                label="¿Quién es la compentencia?"
                :disabled="!opportunity.HasRival"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6"
              ><v-btn text color="primary" @click="currentStep = 3">
                Siguiente
              </v-btn></v-col
            >
            <v-col cols="6">
              <v-btn text @click="sendCancel"> Cancelar </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="opHasCrossBu"
                :items="bUnits"
                item-text="Name"
                item-value="Id"
                chips
                small-chips
                prepend-icon="mdi-domain"
                label="¿Con que Unidad de Negocio deseas hacer cross selling?"
                multiple
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12">
              <v-autocomplete
                v-model="opHasCrossSeller"
                :items="sellers"
                item-text="Name"
                item-value="Id"
                chips
                small-chips
                prepend-icon="mdi-account-tie"
                label="Vendedor"
                multiple
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12">
              <v-select
                v-model="opportunity.Probability"
                :items="probability"
                prepend-icon="mdi-percent"
                label="¿Qué porcentaje de probabilidad le asignas personalmente a esta oportunidad?"
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-textarea
                v-model="opportunity.Commentary"
                prepend-icon="mdi-comment"
                label="Comentarios de la oportunidad"
                rows="1"
                auto-grow
                ref="notes"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-btn
                text
                color="primary"
                @click="saveOpportunity"
                v-if="'Id' in opportunity"
              >
                Guardar
              </v-btn>
              <v-btn text color="primary" @click="createOpportunity" v-else>
                Crear
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn text @click="sendCancel"> Cancelar </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <v-row v-if="false">
      <v-col cols="12">
        <v-card
          style="overflow-x: auto"
          :class="darkTheme ? 'hasSeparatorsD' : 'hasSeparators'"
        >
          <!-- <pre>{{ opportunity }}</pre> -->
          <pre>{{ details }}</pre>
          <!-- <pre>{{ clients }}</pre> -->
          <!-- <pre>{{ sectors }}</pre> -->
          <!-- <pre>{{ contacts }}</pre> -->
          <!-- <pre>{{ bUnits }}</pre> -->
          <!-- <pre>{{ solutions }}</pre> -->
          <!-- <pre>{{ solutionTypes }}</pre> -->
          <!-- <pre>{{ opHasContacts }}</pre> -->
          <!-- <pre>{{ opHasSolutions }}</pre> -->
          <!-- <pre>{{ opHasSolutionsType }}</pre> -->
          <!-- <pre>{{ opHasCrossBu }}</pre> -->
          <!-- <pre>{{ opHasCrossSeller }}</pre> -->
        </v-card>
      </v-col>
    </v-row>
  </DialogFullScreen>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import DialogFullScreen from '@/components/DialogFullScreen.vue'

export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    opportunity: {
      type: Object,
      required: true
    },
    details: {
      type: Object,
      default: null
    }
  },
  components: { DialogFullScreen },
  data() {
    return {
      currentStep: 1,
      formHasErrors: false,
      clients: [],
      sectors: [],
      contacts: [],
      bUnits: [],
      solutions: [],
      solutionTypes: [],
      sellers: [],
      opHasContacts: [],
      opHasSolutions: [],
      opHasSolutionsType: [],
      opHasCrossBu: [],
      opHasCrossSeller: [],
      tmpBU: 0,
      probability: [25, 50, 75, 100],
      blockBu: false
    }
  },
  methods: {
    sendCancel() {
      this.$emit('closeDialog')
    },
    extractIdFromArray(array) {
      let aux = []
      array.forEach((element) => {
        aux.push(element.Id)
      })
      return aux
    },
    getContactName(item) {
      return item.Firtsname + ' ' + item.Lastname
    },
    getClientData(ClientId) {
      this.getSectors(false, ClientId)
      this.getContacts(false, ClientId)
    },
    getBUData(BUId) {
      this.getSolutions(false, BUId)
    },
    getClients(showOverlay) {
      axios
        .get(this.$endpointsBaseUrl + 'CompanyClients/GetClientsWithType', {
          showOverlay
        })
        .then((response) => {
          this.clients = response.data
        })
        .catch((error) => {
          console.log('Error: ', error)
        })
    },
    getSectors(showOverlay, CompanyClientId) {
      let headers = {
        CompanyId: CompanyClientId
      }

      axios
        .get(this.$endpointsBaseUrl + 'Sectors/GetSectorsByCompany', {
          showOverlay,
          headers
        })
        .then((response) => {
          this.sectors = response.data
        })
        .catch((error) => {
          console.log('Error: ', error)
        })
    },
    getContacts(showOverlay, CompanyClientId) {
      let headers = {
        CompanyClientId: CompanyClientId
      }

      axios
        .get(this.$endpointsBaseUrl + 'Contacts/GetContactsByCompany', {
          showOverlay,
          headers
        })
        .then((response) => {
          this.contacts = response.data
        })
        .catch((error) => {
          console.log('Error: ', error)
        })
    },
    getBusinessUnity(showOverlay) {
      axios
        .get(this.$endpointsBaseUrl + 'Business_unity/Get_BusinessUnity', {
          showOverlay
        })
        .then((response) => {
          this.bUnits = response.data
        })
        .catch((error) => {
          console.log('Error: ', error)
        })
    },
    getSolutions(showOverlay, businessUnityId) {
      let headers = {
        Business_unity: businessUnityId
      }

      axios
        .get(this.$endpointsBaseUrl + 'Solution/Get_Solutions', {
          showOverlay,
          headers
        })
        .then((response) => {
          this.solutions = response.data
        })
        .catch((error) => {
          console.log('Error: ', error)
        })
    },
    getSolutionsType(showOverlay) {
      axios
        .get(this.$endpointsBaseUrl + 'TypeSolutions/Get_TypeSolution', {
          showOverlay
        })
        .then((response) => {
          this.solutionTypes = response.data
        })
        .catch((error) => {
          console.log('Error: ', error)
        })
    },
    getSellers(showOverlay) {
      let headers = {
        id: 0
      }

      axios
        .get(this.$endpointsBaseUrl + 'Users/Get_Users', {
          showOverlay,
          headers
        })
        .then((response) => {
          this.sellers = response.data
        })
        .catch((error) => {
          console.log('Error: ', error)
        })
    },
    saveOpportunitiesHasContacts(OpportunityId) {
      let aux = []
      this.opHasContacts.forEach((element) => {
        aux.push({
          OpportunityId: parseInt(OpportunityId),
          ContactId: parseInt(element)
        })
      })
      axios
        .post(
          this.$endpointsBaseUrl +
            'OpportunitiesHasContacs/Create_OpportunitiesHasContacs',
          aux
        )
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    saveOpportunitiesHasSolutions(OpportunityId) {
      let aux = []
      this.opHasSolutions.forEach((element) => {
        aux.push({
          OpportunityId: parseInt(OpportunityId),
          SolutionId: parseInt(element)
        })
      })
      axios
        .post(
          this.$endpointsBaseUrl +
            'OpportunitiesHasSolutions/Create_OpportunitiesHasSolutions',
          aux
        )
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    saveOpportunitiesHasSolutionsType(OpportunityId) {
      let aux = []
      this.opHasSolutionsType.forEach((element) => {
        aux.push({
          OpportunityId: parseInt(OpportunityId),
          TypeSolutionId: parseInt(element)
        })
      })
      axios
        .post(
          this.$endpointsBaseUrl +
            'OpportunitiesHasTypes/Create_OpportunitiesHasTypes',
          aux
        )
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    saveOpportunitiesHasCrossBu(OpportunityId) {
      let aux = []
      this.opHasCrossBu.forEach((element) => {
        aux.push({
          OpportunityId: parseInt(OpportunityId),
          BussinesUnityId: parseInt(element)
        })
      })
      axios
        .post(
          this.$endpointsBaseUrl +
            'CrossSellingBusiness/Create_CrossSellingBusiness',
          aux
        )
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    saveOpportunitiesHasCrossSeller(OpportunityId) {
      let aux = []
      this.opHasCrossSeller.forEach((element) => {
        aux.push({
          OpportunityId: parseInt(OpportunityId),
          UserId: parseInt(element)
        })
      })
      axios
        .post(
          this.$endpointsBaseUrl +
            'CrossSellingSeller/Create_CrossSellingSeller',
          aux
        )
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    createOpportunity() {
      this.formHasErrors = false

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true
        this.$refs[f].validate(true)
      })

      if (!this.formHasErrors) {
        this.opportunity.Budget = parseFloat(this.opportunity.Budget)
        this.opportunity.UserId = this.userData.UserId
        let headers = {
          won: false
        }
        axios
          .post(
            this.$endpointsBaseUrl + 'Opportunities/Create_Opportunity',
            this.opportunity,
            { headers }
          )
          .then((response) => {
            let opid = response.data
            this.saveOpportunitiesHasContacts(opid)
            this.saveOpportunitiesHasSolutions(opid)
            this.saveOpportunitiesHasSolutionsType(opid)
            this.saveOpportunitiesHasCrossBu(opid)
            this.saveOpportunitiesHasCrossSeller(opid)

            this.$store.commit('showSnackBar', {
              text: 'Oportunidad creada',
              color: 'success',
              icon: 'mdi-check-bold'
            })
            this.$emit('savedOpportunity')
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    saveOpportunity() {
      this.formHasErrors = false

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true
        this.$refs[f].validate(true)
      })

      if (!this.formHasErrors) {
        this.opportunity.Budget = parseFloat(this.opportunity.Budget)
        this.opportunity.UserId = this.userData.UserId

        axios
          .put(
            this.$endpointsBaseUrl + 'Opportunities/Update_Opportunity',
            this.opportunity
          )
          .then(() => {
            this.saveOpportunitiesHasContacts(this.opportunity.Id)
            this.saveOpportunitiesHasSolutions(this.opportunity.Id)
            this.saveOpportunitiesHasSolutionsType(this.opportunity.Id)
            this.saveOpportunitiesHasCrossBu(this.opportunity.Id)
            this.saveOpportunitiesHasCrossSeller(this.opportunity.Id)

            this.$store.commit('showSnackBar', {
              text: 'Oportunidad actualizada',
              color: 'success',
              icon: 'mdi-check-bold'
            })
            this.$emit('savedOpportunity')
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
  },
  computed: {
    ...mapState('account', ['darkTheme', 'userData']),
    form() {
      return {
        client: this.opportunity.CompanyClientId,
        nombre: this.opportunity.Name,
        sector: this.opportunity.SectorId,
        deadline: this.opportunity.Deadline,
        presupuesto: this.opportunity.Budget,
        bunits: this.tmpBU,
        solutions: this.opHasSolutions,
        solutionsType: this.opHasSolutionsType
      }
    }
  },
  mounted() {
    this.getClients(false)
    this.getBusinessUnity(false)
    this.getSolutionsType(false)
    this.getSellers(false)
    if ('Id' in this.opportunity && this.details.BusinessUnities.length > 0) {
      if (this.opportunity.Id > 0) {
        this.blockBu = true
      }
      this.getClientData(this.opportunity.CompanyClientId)
      this.getBUData(this.details.BusinessUnities[0].Id)
      this.opHasContacts = this.extractIdFromArray(this.details.Contacs)
      this.opHasSolutions = this.extractIdFromArray(this.details.Solutions)
      this.opHasSolutionsType = this.extractIdFromArray(
        this.details.TypeSolution
      )
      this.opHasCrossBu = this.extractIdFromArray(
        this.details.CrossBusinessUnitites
      )
      this.opHasCrossSeller = this.extractIdFromArray(this.details.CrossSeller)
      this.tmpBU = this.details.BusinessUnities[0].Id
    }
  }
}
</script>
